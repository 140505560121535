import React from 'react'
import Link from 'gatsby-link'
import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa';

const Header = ({ hideHeaderImage }) =>
  <div className="Header text-center uppercase py-6 tracking-widest container" itemScope itemType="http://schema.org/Person" >
    <Link to="/" itemProp="url">
      { (hideHeaderImage ? null : <div><span className="Header__image"><div/></span><br/></div> ) }

      <h1 className="font-bold text-4xl leading-none" itemProp="name">Conner  “on the” Fritz</h1>
      <div className="">
        Engineering Leader & Developer
      </div>
    </Link>
    <div className="flex flex-wrap justify-around items-center content-center my-4 font-thin text-sm text-center" >
      {/* <Link to="/about" className="Link hover:text-blue-500 mx-2" activeClassName="Link_active text-blue-500 font-medium">About</Link> */}
      <Link to="/blog" className="Link hover:text-blue-500 mx-2" activeClassName="Link_active text-blue-500 font-medium" partiallyActive={true}>Words</Link>

      {/* <Link to="/contact" className="Link hover:text-blue-500 mx-2" activeClassName="Link_active text-blue-500 font-medium" partiallyActive={true}>Contact</Link> */}
        <a href="https://github.com/connerfritz" className="Link hover:text-blue-500 px-2 pb-1" target="_blank" rel="noreferrer"><FaGithub className=""/></a>
        <a href="https://twitter.com/connerfritz" className="Link hover:text-blue-500 px-2 pb-1" target="_blank" rel="noreferrer"><FaTwitter className=""/></a>
        <a href="https://linkedin.com/in/connerfritz" className="Link hover:text-blue-500 px-2 pb-1" target="_blank" rel="noreferrer"><FaLinkedin className="" /></a>

    </div>

  </div>

export default Header
