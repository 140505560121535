import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"

import './index.css'

import SocialPicture from '../images/socialmediawhite.jpg';

import Header from './header';
import Footer from './footer';

const TemplateWrapper = ({ children, hideHeaderImage }) => {
  const { site: { siteMetadata: { title, aboutMe, siteUrl } } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            aboutMe
          }
        }
      }
    `
  )

  return (
    <div className="min-h-screen bg-gray-100">
      <Helmet
        title="Conner Fritz - Software Developer"
        meta={[
          { name: "robots", content: "index, follow" },
          { name: 'theme-color', content: '#68c0ff'}
        ]}
        link={[
          { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
          { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
          { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" }
        ]}
      >
        <meta itemprop="name" content={title} />
        <meta itemprop="description" content={aboutMe} />
        <meta itemprop="image" content={SocialPicture} />
        <meta itemprop="author" content="Conner Fritz" />
        <meta itemprop="keywords" content="Conner Fritz,developer,startups,engineer,Toronto,software,machine learning,rails,ruby,javascript,ember,react,node,nodejs,phoenix,elixir,python" />

        <meta itemprop="profile:first_name" content="Conner" />
        <meta itemprop="profile:last_name" content="Fritz" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={aboutMe} />
        <meta property="og:image" content={SocialPicture} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={aboutMe} />
        <meta name="twitter:image" content={SocialPicture} />
        <meta name="twitter:site" content="@connerfritz" />
        <meta name="twitter:creator" content="@connerfritz" />
      </Helmet>
      <Header hideHeaderImage={hideHeaderImage} />
      <div className="container min-h-3/4">
        {children}
      </div>

      <Footer />
    </div>
  )
}

export default TemplateWrapper
